export const icons = {
  OSRS_ACCOUNT: '/images/imagesNext/reviewsProducts/accounts.png',
  OSRS_ITEM: '/images/imagesNext/reviewsProducts/items.png',
  OSRS_GOLD: '/images/imagesNext/reviewsProducts/osrs-gold.png',
  RS3_GOLD: '/images/imagesNext/reviewsProducts/osrs-gold.png',
  SELL_RS_GOLD: '/images/imagesNext/reviewsProducts/dollar-coin.png',
  REWARDS: '/images/imagesNext/reviewsProducts/rewards.png',
  SERVICES: '/images/imagesNext/reviewsProducts/services.png'
};
export const productTitles = {
  OSRS_ACCOUNT: 'Accounts',
  OSRS_ITEM: 'Items',
  OSRS_GOLD: 'Gold',
  RS3_GOLD: 'Gold',
  SELL_RS_GOLD: 'Gold',
  REWARDS: 'Chests',
  SERVICES: 'Services'
};
