export const VerifiedIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={18}
    height={18}
    viewBox='0 0 18 18'
    fill='none'
    {...props}
  >
    <path
      fill='#28B9B0'
      fillRule='evenodd'
      d='M15.755 6.702c.89.642 1.495 1.636 1.495 2.798 0 1.162-.605 2.157-1.495 2.797.18 1.082-.1 2.219-.92 3.038-.821.821-1.958 1.094-3.035.919-.638.893-1.64 1.496-2.8 1.496-1.162 0-2.159-.605-2.798-1.497-1.079.176-2.216-.096-3.038-.918-.821-.821-1.094-1.958-.913-3.038C1.363 11.657.75 10.663.75 9.5c0-1.163.612-2.159 1.502-2.798-.182-1.079.091-2.216.913-3.037.82-.82 1.957-1.1 3.043-.92C6.844 1.853 7.84 1.25 9 1.25c1.159 0 2.16.602 2.799 1.495 1.082-.18 2.217.101 3.036.92.82.819 1.101 1.956.92 3.037Zm-3.32-.063a.749.749 0 0 1 .175 1.047l-3.75 5.25a.75.75 0 0 1-1.14.094l-2.25-2.25a.75.75 0 0 1 1.06-1.06l1.624 1.623 3.236-4.53a.75.75 0 0 1 1.046-.173Z'
      clipRule='evenodd'
    />
  </svg>
);
