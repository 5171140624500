import Image from 'next/image';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from '@material-ui/core/Link';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {productTitles} from 'src/componentsNext/ReviewsSwiperNext/constants';
import {icons} from 'src/componentsNext/ReviewsSwiperNext/constants';
import {VerifiedIcon} from 'src/componentsNext/icons/reviews/VerifiedIcon';

import {ReviewsCustomRating} from './ReviewsCustomRating';

const useStyles = makeStyles(theme => ({
  slide: {
    height: 224,
    maxHeight: '-webkit-fit-content',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 4,
    background:
      'left / cover url("/images/imagesNext/reviewsSwiperSlideBg.png") no-repeat fixed',

    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      height: 190,
      borderRadius: 6,
      paddingBottom: theme.spacing(1.5)
    },

    '&:hover': {
      backgroundImage:
        'linear-gradient(rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), url("/images/imagesNext/reviewsSwiperSlideBg.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'fixed'
    }
  },
  slideContent: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 128px',
    gap: theme.spacing(2),
    borderRadius: 4,

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1),
      borderRadius: 6,
      gridTemplateColumns: '1fr 80px'
    }
  },
  leftColumn: {
    display: 'grid',
    gap: theme.spacing(1),
    gridAutoRows: 'max-content',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 162,
      gap: theme.spacing(0.5),
      gridAutoRows: 'max-content'
    }
  },
  rightColumn: {
    marginTop: 'auto',

    [theme.breakpoints.down('sm')]: {
      height: 162,
      marginTop: 'unset'
    }
  },
  header: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '48px 1fr',
    gap: theme.spacing(1),
    color: theme.palette.common.white
  },
  icon: {
    position: 'relative',
    width: 48,
    height: 48,
    filter: 'drop-shadow(0px 0px 25px rgba(239, 159, 56, 0.35))'
  },
  image: {
    position: 'relative',
    width: 128,
    height: 192,
    marginTop: 'auto',
    filter: 'drop-shadow(0px 0px 25px rgba(51, 157, 255, 0.45))',

    [theme.breakpoints.down('sm')]: {
      // marginBottom: theme.spacing(1),
      // width: 100
      width: 80,
      height: 162
    }
  },
  review: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.common.white,
    ...extendedTypography.body2RegularDesktop,
    lineHeight: '21px',
    width: '-webkit-fill-available',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5,
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.body2RegularMobile
    }
  },
  userNameWithIcon: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      // marginTop: theme.spacing(0.5)
    }
  },
  userName: {
    maxWidth: 200,
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 120,
      marginTop: theme.spacing(0.5)
    }
  },
  productType: {
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.secondary.main
    }
  },
  link: {
    '&:hover': {
      opacity: 1
    }
  }
}));

export const ReviewsSlide = ({review}) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const item = review?.node;

  return (
    <Link
      href={
        item?.link ||
        'https://www.reviews.io/company-reviews/store/probemas-com'
      }
      target='_blank'
      rel='noopener noreferrer'
      classes={{root: classes.link}}
    >
      <div className={classes.slide}>
        <div className={classes.slideContent}>
          <div className={classes.leftColumn}>
            <div className={classes.header}>
              <div className={classes.icon}>
                <Image
                  src={icons[item.productType]}
                  alt={item.productType}
                  // width={48}
                  // height={48}
                  loading='eager'
                  layout='fill'
                  objectFit='contain'
                />
              </div>
              <div>
                <Typography
                  className={classes.productType}
                  style={
                    isDownSm
                      ? extendedTypography.body1RegularMobile
                      : extendedTypography.body1MediumDesktop
                  }
                >
                  {productTitles[item.productType]}
                </Typography>
                <ReviewsCustomRating rating={item.stars} />
              </div>
            </div>

            <div className={classes.userNameWithIcon}>
              <Typography
                className={classes.userName}
                style={
                  isDownSm
                    ? extendedTypography.body2MediumMobile
                    : extendedTypography.body2SemiboldDesktop
                }
              >
                {item.user}
              </Typography>
              <div title='Verified Customer'>
                <VerifiedIcon />
              </div>
            </div>

            <Typography className={classes.review}>
              {item.reviewText}
            </Typography>
          </div>
          {/*{!isDownSm && (*/}
          <div className={classes.rightColumn}>
            {item?.image?.url && (
              <div className={classes.image}>
                <Image
                  src={item.image.url}
                  objectPosition='bottom'
                  alt='image review'
                  layout='fill'
                  objectFit='contain'
                  loading='eager'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

ReviewsSlide.propTypes = {
  reviews: PropTypes.object
};
