import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {useTheme} from '@material-ui/core/styles';

import {StarIcon} from 'src/componentsNext/icons/reviews/StarIcon';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 16,
    gap: 2
  },
  starIcon: {
    display: 'flex',
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2
  },
  ratingIcon: {
    display: 'block'
  }
}));

const RatingStarIcon = ({rating}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <span
      className={clsx(classes[Math.ceil(parseFloat(rating))], classes.starIcon)}
      style={{
        backgroundColor: theme.palette.starsRating[5]
        // theme.palette.starsRating[Math.ceil(parseFloat(rating))]
      }}
    >
      <StarIcon color={theme.palette.common.white} width={9} height={9} />
    </span>
  );
};

RatingStarIcon.propTypes = {
  rating: PropTypes.number
};

export const ReviewsCustomRating = ({rating}) => {
  const classes = useStyles();
  return (
    <Rating
      classes={{root: classes.root, icon: classes.ratingIcon}}
      value={rating}
      precision={0.5}
      readOnly
      icon={<RatingStarIcon rating={rating} />}
      emptyIcon={<RatingStarIcon rating={0} />}
    />
  );
};

ReviewsCustomRating.propTypes = {
  rating: PropTypes.number
};
