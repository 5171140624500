import {Swiper, SwiperSlide} from 'swiper/react';
import {alpha, makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import range from 'lodash/range';
import {Pagination} from 'swiper/modules';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {ReviewsSlide} from './components/ReviewsSlide';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export const useStyles = makeStyles(theme => ({
  swiperWrapper: {
    position: 'relative'

    // [theme.breakpoints.down('sm')]: {marginRight: -theme.spacing(2)}
  },
  swiper: {
    overflow: 'visible',

    // [theme.breakpoints.down('sm')]: {overflow: 'hidden'},

    // '&:before': {
    //   content: '""',
    //   position: 'absolute',
    //   top: 0,
    //   left: '-100%',
    //   zIndex: 1000,
    //   backgroundColor: theme.palette.backgrounds.dark,
    //   height: 'calc(100% + 30px)',
    //   width: '100%'
    // },

    '& .swiper-pagination-bullet': {
      width: 16,
      height: 16,
      opacity: 1,
      margin: '0 10px',
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      border: `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,

      '&:hover': {backgroundColor: theme.palette.secondary.main},

      [theme.breakpoints.down('sm')]: {
        width: 10,
        height: 10
      }
    },

    '& .swiper-pagination-bullet-active': {
      backgroundColor: theme.palette.secondary.main
    },

    '& .swiper-pagination': {
      top: 'unset',
      bottom: -60,

      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        marginTop: theme.spacing(4.5),
        bottom: 'unset'
      }
    },

    '& .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet':
      {
        margin: '0 10px',

        [theme.breakpoints.down('sm')]: {margin: '0 6px'}
      }
  },
  slideWrapper: {
    // maxWidth: 404,
    height: 224,
    borderRadius: 12,
    overflow: 'hidden',
    border: `1px solid #414D67`,

    [theme.breakpoints.down('sm')]: {
      minHeight: 190,
      height: '100%'
    }
  }
}));

export const ReviewsSwiper = ({testimonials, customSlide}) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        ' probemas-bullet ' +
        '">' +
        ' ' +
        '</span>'
      );
    }
  };

  return (
    <div className={classes.swiperWrapper}>
      <Swiper
        modules={[Pagination]}
        pagination={pagination}
        breakpoints={{
          6000: {
            slidesPerView: 10
          },
          4000: {
            slidesPerView: 7
          },
          3000: {
            slidesPerView: 5
          },
          2000: {
            slidesPerView: 4
          },
          960: {
            slidesPerView: 3
          },
          600: {
            slidesPerView: 2.2
          },
          200: {
            slidesPerView: 1.2
          }
        }}
        className={classes.swiper}
        spaceBetween={20}
        // loop={true}
      >
        {customSlide && (
          <SwiperSlide className={classes.slideWrapper}>
            {customSlide}
          </SwiperSlide>
        )}
        {!testimonials || testimonials.length === 0
          ? range(0, 4).map(index => (
              <SwiperSlide key={index} className={classes.slideWrapper}>
                {<Skeleton variant='rect' width='100%' height='100%' />}
              </SwiperSlide>
            ))
          : testimonials.map(item => {
              return (
                <SwiperSlide
                  key={item.node.id}
                  className={classes.slideWrapper}
                >
                  {<ReviewsSlide key={item.node.id} review={item} />}
                </SwiperSlide>
              );
            })}
      </Swiper>
    </div>
  );
};

ReviewsSwiper.propTypes = {
  testimonials: PropTypes.array,
  customSlide: PropTypes.node
};
