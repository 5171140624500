export const DoubleArrowRightIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      d='M5.3125 15.0547L4.4375 14.1797L8.5625 10.0547L4.4375 5.92969L5.3125 5.05469L10.3125 10.0547L5.3125 15.0547ZM10.5833 15.0547L9.70833 14.1797L13.8333 10.0547L9.70833 5.92969L10.5833 5.05469L15.5833 10.0547L10.5833 15.0547Z'
      fill='currentColor'
    />
  </svg>
);
