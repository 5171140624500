import Image from 'next/image';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import {DoubleArrowRightIcon} from 'src/componentsNext/icons/arrows/DoubleArrowRightIcon';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {PageSlugs} from 'src/core/urls';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 290,
    width: 310,
    minHeight: 514,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      height: 340,
      minHeight: 440
    }
  },
  main: {
    width: 'inherit',
    height: 393,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('images/imagesNext/itemsPage/bannerBgDesktop.png')`,
    borderRadius: theme.spacing(1.5),
    // borderWidth: 1,
    opacity: 1,
    top: 120,
    position: 'relative',
    backgroundSize: 'cover',

    [theme.breakpoints.down('sm')]: {
      top: 100,
      height: 340,
      backgroundImage: `url('images/imagesNext/itemsPage/bannerBgMobile.png')`
    }
  },
  imageBox: {
    minWidth: 310,
    minHeight: 218,
    position: 'absolute',
    top: 0,

    [theme.breakpoints.down('sm')]: {
      left: 0,
      right: 0,
      minWidth: 256,
      minHeight: 180
    }
  },
  image: {
    width: '100%',
    height: '100%'
  },
  titleImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 113,
    width: 256,

    [theme.breakpoints.down('sm')]: {
      width: 224,
      paddingTop: theme.spacing(11.5)
    }
  },
  subTitle: {
    textAlign: 'center',
    color: theme.palette.common.white,
    ...extendedTypography.h5Desktop,
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      ...extendedTypography.h5MediumMobile
    }
  },
  desc: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    ...extendedTypography.caption1Desktop,
    padding: theme.spacing(1, 3.75, 0, 3.75),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      ...extendedTypography.body2MediumMobile
    }
  },
  bottomText: {
    display: 'flex',
    gap: theme.spacing(0.5),
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    ...extendedTypography.body1MediumDesktop,
    lineHeight: '20px',
    '& p': {
      margin: 0
    }
  },
  buttonBox: {
    textAlign: 'center',
    padding: theme.spacing(0, 5),
    marginTop: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3)
    }
  }
}));

const useItemStyles = makeStyles(theme => ({
  itemText: {
    ...extendedTypography.body1MediumDesktop,

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.subtitleMediumMobile
    }
  },
  arrows: {
    color: theme.palette.secondary.main,
    width: 20,
    height: 20
  }
}));

const items = [
  {
    text: 'Purchase',
    withArrow: true
  },
  {
    text: 'Earn Points',
    withArrow: true
  },
  {
    text: 'Redeem',
    withArrow: false
  }
];

const ItemsBottom = ({text, withArrow}) => {
  const classes = useItemStyles();

  if (withArrow === true) {
    return (
      <>
        <Typography classes={{root: classes.itemText}}>{text}</Typography>{' '}
        <div className={classes.arrows}>
          {' '}
          <DoubleArrowRightIcon />
        </div>
      </>
    );
  }

  return <Typography classes={{root: classes.itemText}}>{text}</Typography>;
};

ItemsBottom.propTypes = {
  text: PropTypes.string,
  withArrow: PropTypes.bool
};

export const LoyaltyBannerNext = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.titleImage}>
          <Image
            width={256}
            height={48}
            alt='earn rewards'
            src='/images/imagesNext/rewards-banner/earn-rewards.png'
          />
        </div>
        <Typography classes={{root: classes.subTitle}}>
          Every Time You Buy
        </Typography>
        <Typography classes={{root: classes.desc}}>
          Log in and start earning Loyalty Points for every Purchase.
        </Typography>
        <div className={classes.bottomText}>
          {items.map((item, index) => (
            <ItemsBottom
              key={index}
              text={item.text}
              withArrow={item.withArrow}
            />
          ))}
        </div>
        <div className={classes.buttonBox}>
          <Link href={`/${PageSlugs.LOYALTY_REWARDS_STORE}`}>
            <Button
              fullWidth
              size='small'
              variant='contained'
              color='secondary'
            >
              See Rewards
            </Button>
          </Link>
        </div>
      </div>
      <div className={classes.imageBox}>
        <Image
          className={classes.image}
          src='/images/imagesNext/rewards-banner/loyalty-box-new.png'
          alt='Loyalty program items image'
          layout='fill'
          objectFit='contain'
          quality={100}
          loading='eager'
        />
      </div>
    </div>
  );
};
