export const StarIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={10}
    height={10}
    viewBox='0 0 10 10'
    fill='none'
    {...props}
  >
    <path
      fill='#fff'
      d='m5 7.626 2.173-.582L8.08 10 5 7.626Zm5-3.821H6.176L5 0 3.824 3.805H0l3.095 2.359L1.92 9.969 5.015 7.61 6.92 6.164 10 3.805Z'
    />
  </svg>
);
